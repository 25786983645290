/**
 * AuthButton
 * 
 * @Author: Focci
 * @Date: 2025-01-07 15:57:57
 * @Last Modified by: Focci
 * @Last Modified time: 2025-01-07 15:57:57
 */

// import PropTypes from 'prop-types'
import clsx from 'clsx'

export default function AuthButton({
  children,
  className = 'bg-white hover:bg-white/80',
  onClick = () => { }
}) {
  return (
    <div
      onMouseDown={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
      className={clsx([
        'rounded-[.2rem] h-6 px-2 md:px-3 cursor-pointer',
        'flex items-center select-none whitespace-nowrap',
        className
      ])}
    >
      {children}
    </div>
  )
}

// AuthButton.propTypes = {
//   className: PropTypes.string,
// }
