/**
 * TransferToEnglish
 * 
 * @Author: Focci
 * @Date: 2025-02-10 12:14:56
 * @Last Modified by: Focci
 * @Last Modified time: 2025-02-10 12:14:56
 */

'use client'

// import PropTypes from 'prop-types'
import clsx from 'clsx'
import useLocaleSwitch from '@hook/useLocaleSwitch'
import useApp from '@hook/useApp'
import { locales } from '@i18n/config'
import { useCallback, useMemo } from 'react'
import { useLocale } from 'next-intl'
import { Tooltip } from '@comp/material'
import SVGTranslate from '@img/translate.svg'
import AuthButton from './AuthButton'

export default function TransferToEnglish({
  defaultClassName = 'text-white hover:bg-white/10',
  activeClassName = 'bg-primary text-white'
}) {
  const locale = useLocale()
  const { isMobile } = useApp()
  const { switchLanguage } = useLocaleSwitch()
  const isEn = useMemo(() => locale === locales[1], [locale])

  const handleClick = useCallback(() => {
    !isEn && switchLanguage(locales[1])
  }, [isEn, switchLanguage])

  // https://7thave.atlassian.net/browse/HT-4954?focusedCommentId=18499
  if(isMobile) {
    return null
  }

  return (
    <Tooltip title="Translate to English (Google)" placement="bottom" arrow>
      <div>
        <AuthButton 
          onClick={handleClick}
          className={clsx([
            isEn ? activeClassName : defaultClassName,
          ])}
        >
          <SVGTranslate />
          <span className="ml-1">English</span>
        </AuthButton>
      </div>
    </Tooltip>
  )
}

// TransferToEnglish.propTypes = {
//   className: PropTypes.string,
// }
