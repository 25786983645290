/**
 * useLocaleSwitch
 * 
 * @Author: Focci
 * @Date: 2024-01-10 12:18:16
 * @Last Modified by: Focci
 * @Last Modified time: 2024-01-10 12:18:16
 */

// import PropTypes from 'prop-types'
import SVGNZFlag from '@img/nz-flag.svg'
import SVGAUFlag from '@img/au-flag.svg'
import { cookieGet, cookieSet, langOpts } from '@lib/utils'
import { getCookieOption } from '@lib/get-cookie-option'
import { useCallback, useMemo } from 'react'
import { useTranslations } from 'use-intl'
import { filter } from 'lodash-es'
import { defaultLocale, localCookieName, usePathname } from '@i18n/config'
import { 
  REFRESH_TOKEN_NAME, TOKEN_NAME, UUID_PROP, ENQUIRY_FORM_PARAM, 
  HG_LISTING_MODE, baseUrls, countryAlias, HG_CURRENT_COUNTRY_NAME 
} from '@lib/config'
import { useLocale } from 'next-intl'

/**
 * 为了兼容以前老的cookie
 */
function resetCookie(lang) {
  // 删除旧的数据
  cookieSet(localCookieName, '', { expires: 0 })
  cookieSet(TOKEN_NAME, '', { expires: 0 })
  cookieSet(REFRESH_TOKEN_NAME, '', { expires: 0 })
  cookieSet(UUID_PROP, '', { expires: 0 })
  cookieSet(ENQUIRY_FORM_PARAM, '', { expires: 0 })
  cookieSet(HG_LISTING_MODE, '', { expires: 0 })

  const option = getCookieOption()
  const vb = cookieGet(TOKEN_NAME)
  const vc = cookieGet(REFRESH_TOKEN_NAME)
  const vd = cookieGet(UUID_PROP)
  const ve = cookieGet(ENQUIRY_FORM_PARAM)
  const vf = cookieGet(HG_LISTING_MODE)

  // 更新新的数据
  cookieSet(localCookieName, lang, option)
  vb && cookieSet(TOKEN_NAME, vb, option)
  vc && cookieSet(REFRESH_TOKEN_NAME, vc, option)
  vd && cookieSet(UUID_PROP, vd, option)
  ve && cookieSet(ENQUIRY_FORM_PARAM, ve, option)
  vf && cookieSet(HG_LISTING_MODE, vf, option)
}

const flagIcon = {
  [countryAlias.nz]: SVGNZFlag,
  [countryAlias.au]: SVGAUFlag,
}

export default function useLocaleSwitch() {
  const pathname = usePathname()
  const currentCuntry = process.env.NEXT_PUBLIC_COUNTRY
  const currentLang = useLocale()
  const langLabel = useMemo(() => (
    filter(langOpts, (f) => f.value === currentLang)[0]?.label
  ), [currentLang])

  const tc = useTranslations('common')
  const countryOptions = useMemo(() => [
    { label: tc('nz'), value: countryAlias.nz, icon: <SVGNZFlag />, enName: 'New Zealand' },
    { label: tc('au'), value: countryAlias.au, icon: <SVGAUFlag />, enName: 'Australia' },
  ], [tc])

  const countryNames = useMemo(() => ({
    [countryAlias.nz]: tc('nz'),
    [countryAlias.au]: tc('au'),
  }), [tc])

  const [isAU, isNZ] = useMemo(() => [
    currentCuntry === countryAlias.au,
    currentCuntry === countryAlias.nz,
  ], [currentCuntry])

  /**
   * 切换国家
   */
  const switchCountry = useCallback((country) => {
    if(country !== currentCuntry) {
      const langSuffix = currentLang === defaultLocale ? '' : `/${currentLang}`
      const option = getCookieOption()

      cookieSet(HG_CURRENT_COUNTRY_NAME, country, option)
      window.location.replace(`${baseUrls[country]}${langSuffix}`)
    }
  }, [currentCuntry, currentLang])

  /**
   * 切换语言
   */
  const switchLanguage = useCallback((lang) => {
    const { search } = window.location
    const url = `/${lang}${pathname}${search}`
    
    try { localStorage.setItem('set_lang', 1) } catch (err) { /**/ }
    
    resetCookie(lang)
    window.location.replace(url)
  }, [pathname])

  /**
   * 获取国家图标及名称
   * 
   * @param {String} flag NZ or AU 
   * @returns {Object}
   */
  function getCountry(flag) {
    const FlagIcon = flagIcon[flag] || 'span'
    return {
      FlagIcon,
      countryName: countryNames[flag] || countryNames[countryAlias.nz]
    }
  }
  
  return {
    switchCountry,
    switchLanguage,
    getCountry,
    flagIcon,
    country: currentCuntry,
    countryName: countryNames[currentCuntry],
    countryOptions,
    lang: currentLang,
    langLabel,
    isAU,
    isNZ,
  }
}
