/**
 * HeadBarResposiveHeader
 * 
 * @Author: Focci
 * @Date: 2025-01-07 15:47:26
 * @Last Modified by: Focci
 * @Last Modified time: 2025-01-07 15:47:26
 */

'use client'

import useApp from '@hook/useApp'
import useLazyComponent from '@hook/useLazyComponent'
import { useEffect } from 'react'

export default function HeadBarResposiveHeader({ flag, ...props }) {
  const { isMobile } = useApp()
  const { Wedget: HeadBarResposive, getWedget } = useLazyComponent()

  useEffect(() => {
    isMobile && getWedget(() => import('./index'))
  }, [getWedget, isMobile])

  return (<HeadBarResposive flag={flag} {...props} />)
}
